import { Metric } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: Metric) => void) => {
	if (onPerfEntry && typeof onPerfEntry === 'function') {
		import('web-vitals').then(({ onCLS, onFCP, onLCP, onTTFB }) => {
			onCLS(onPerfEntry);
			onFCP(onPerfEntry);
			onLCP(onPerfEntry);
			onTTFB(onPerfEntry);
		});
	}
};

export default reportWebVitals;
